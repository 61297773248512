<template>
  <div class="activities" v-if="canView">
    <page-header :title="activitiesTitle" :icon="activitiesIcon" v-if="!isYouthHome" :links="getLinks()">
    </page-header>
    <page-header :title="youthHomeLabel" :icon="youthHomeIcon" v-else>
    </page-header>
    <b-row>
      <b-col cols="8">
        <div>
           <ul class="app-menu">
             <li class="first-row" v-if="!isYouthHome">
               <search-activity
                 :school-year="selectedSchoolYear"
                 :initial="activitySearch"
                 :category="category"
                 @search="onSearch"
               ></search-activity>
             </li>
             <li>
               <loading-gif :loading-name="activitiesCountLoading"></loading-gif>
               <div v-if="!isLoading(activitiesCountLoading)">
                 <b-row>
                   <b-col cols="6">
                     <a class="btn-show-activities" href @click.prevent="onShowAllActivities">
                       <i :class="activitiesIcon" v-if="!isYouthHome"></i>
                       <i :class="youthHomeIcon" v-else></i>
                       {{ activitiesLabel(activitiesCount) }}
                     </a>
                   </b-col>
                   <b-col cols="3">
                     <b-select
                       v-if="displayedActivities.length > 0"
                       v-model="selectedDisplayOption"
                     >
                       <b-select-option
                        v-for="choice of displayOptions"
                        :key="choice.id"
                        :value="choice"
                        class="select-small"
                       >
                         {{ choice.name }} ({{ getActivityCounter(choice) }})
                       </b-select-option>
                     </b-select>
                   </b-col>
                   <b-col cols="3" class="text-right" v-if="activitySearch === '' && showAllActivities">
                     <a
                        href
                        @click.prevent="selectMultiActivitiesForPrint()"
                        class="btn btn-sm btn-secondary"
                        v-b-tooltip="'Feuilles de présences multiples'"
                      >
                        <i class="fa fa-list-alt"></i>
                     </a>
                     &nbsp;
                     <a class="btn btn-xs btn-back-all btn-secondary" href @click.prevent="onShowMenu">
                       <i class="fa fa-chevron-left"></i> Retour
                     </a>
                   </b-col>
                 </b-row>
               </div>
             </li>
             <li v-if="activitySearch && !isLoading(activitiesLoading) && !isYouthHome">
               <loading-gif :loading-name="searchLoading"></loading-gif>
               <div v-if="!isLoading(searchLoading)">
                 <b-row>
                   <b-col cols="8">
                     <i class="fa fa-search"></i> Recherche : {{ activitiesLabel(searchedActivities.length) }}
                   </b-col>
                   <b-col cols="4" class="text-right">
                     <a
                       v-if="displayedActivities.length > 0"
                       href
                       @click.prevent="selectMultiActivitiesForPrint()"
                       class="btn btn-sm btn-secondary"
                       v-b-tooltip="'Feuilles de présences multiples'"
                     >
                      <i class="fa fa-list-alt"></i>
                     </a>
                     &nbsp;
                     <a class="btn btn-xs btn-back-search btn-secondary" href @click.prevent="onBack">
                       <i class="fa fa-chevron-left"></i> Retour
                     </a>
                   </b-col>
                 </b-row>
               </div>
             </li>
             <li v-if="showMenu">
               <router-link :to="{ name: 'contact-members', }">
                 <i class="fa fa-envelope"></i> Contacter les membres
               </router-link>
             </li>
             <li v-if="!isYouthHome && showMenu">
               <router-link
                 :to="{ name: 'activity-add', params: { youthHome: youthHome, }, query: { category: this.category, }, }"
               >
                 <i class="fa fa-plus"></i> Ajout d'activité{{ activitiesSuffix }}
               </router-link>
             </li>
             <li v-if="!isYouthHome && showMenu && hasPerm('activities.view_activitymanager')">
               <router-link :to="{ name: 'activity-managers', query: { category: category, }, }">
                 <i class="fa fa-people-line"></i> Animateurs{{ activitiesSuffix }}
               </router-link>
             </li>
             <li v-if="!isYouthHome && showMenu && hasPerm('agenda.view_activitydate')">
               <router-link :to="{ name: 'agenda-activity-dates', }">
                 <i class="fa fa-location-dot"></i> Occupation des lieux
               </router-link>
             </li>
             <li v-if="!isYouthHome && showMenu && hasAllPerms(['activities.view_coreactivity', 'home.view_place'])">
               <router-link :to="{ name: 'agenda-activities', }">
                 <i class="fa fa-calendar-day"></i> Planning des activités
               </router-link>
             </li>
             <li v-if="!isYouthHome && showMenu && hasPerm('activities.view_coreactivityinscription')">
               <router-link :to="{ name: 'trying-activity-inscriptions', query: { category: category, },  }">
                 <i class="fa fa-school-lock"></i> Inscriptions{{ activitiesSuffix }} à l'essai
               </router-link>
             </li>
             <li v-if="showMenu && hasAllPerms(['payments.add_invoice', 'adhesions.view_adhesion'])">
              <router-link :to="{ name: 'adhesions-invoicing', }">
                <i class="fa fa-receipt"></i> Facturation Adhésions
              </router-link>
            </li>
            <li v-if="showMenu && hasAllPerms(['payments.add_invoice', 'activities.view_coreactivity'])">
              <router-link
                :to="{ name: 'activities-invoicing', query: { category: this.category, }, }"
              >
                <i class="fa fa-receipt"></i> Facturation Activités{{ activitiesSuffix }}
              </router-link>
            </li>
             <li v-if="showMenu && hasPerm('activities.view_coreactivity')">
              <router-link
                :to="{ name: 'multi-activities-inscriptions', query: { category: this.category, }, }"
              >
                <i class="fa fa-th-list"></i> Inscrits aux activités{{ activitiesSuffix }}
              </router-link>
            </li>
             <pages-list apply-to="activities" v-if="showMenu"></pages-list>
           </ul>
        </div>
        <loading-gif :loading-name="activitiesLoading"></loading-gif>
        <div>
          <b-row
            v-for="activity in displayedActivities" :key="activity.id"
            class="row-line activity-line"
            :class="{ disabled: activity.disabled, heir: isHeirHighlighted(activity)}"
            :style="activity.getHeaderStyle()"
          >
            <b-col :cols="!isYouthHome ? 6 : 4">
              <span class="badge" :style="activity.schoolYear.getHeaderStyle()">
                {{ activity.schoolYear.name }}
              </span>&nbsp;
              <router-link
                :to="getLinkToActivity(activity)" class="activity-name" :style="activity.getHeaderStyle()"
              >
                <i class="fa fa-calendar-alt" v-if="activity.inheritFrom !== null"></i>
                {{ activity.name }}
              </router-link>
              <a
                class="btn btn-secondary btn-xs"
                href
                @click.prevent="toggleHeirs(activity)"
                v-if="activity.heirs.length"
                :style="activity.getHeaderStyle()"
              >
                <i class="fa fa-chevron-down heirs-visible" v-if="areHeirsVisible(activity)"></i>
                <i class="fa fa-chevron-right" v-else></i>
                <counter-label :counter="activity.heirs.length" label="séance"></counter-label>
              </a>
              <div v-if="activity.keywords">
                <span class="keyword" v-for="keyword in activity.getKeywords()" :key="keyword">
                  {{ keyword }}
                </span>
              </div>
            </b-col>
            <b-col>
              <activity-inscriptions-count-badge :activity="activity" block>
              </activity-inscriptions-count-badge>
            </b-col>
            <b-col cols="2" class="buttons-cols">
              <disable-activity-button :activity="activity" @updated="onActivityUpdated($event)">
              </disable-activity-button>
              <router-link
                :to="getLinkToActivityPayments(activity)"
                class="btn btn-sm btn-secondary"
                title="Paiements"
                v-b-tooltip
                v-if="!isYouthHome"
              >
                <i class="fa fa-euro"></i>
              </router-link>
              <router-link
                :to="getLinkToActivitySeances(activity)"
                class="btn btn-sm btn-secondary"
                title="Feuille de présences"
                v-b-tooltip
                v-if="!isYouthHome"
              >
                <i class="fa fa-list-alt"></i>
              </router-link>
            </b-col>
          </b-row>
        </div>
        <div ref="excelTable" v-show="false">
          <table>
            <tr>
              <th>Année scolaire</th>
              <th>Activité</th>
              <th>Nb d'inscrits</th>
              <th>A l'essai</th>
              <th>En attente</th>
              <th>Minimum</th>
              <th>Maximum</th>
            </tr>
            <tr v-for="activity in displayedActivities" :key="activity.id">
              <td>{{ activity.schoolYear.name }}</td>
              <td>{{ activity.name }}</td>
              <td class="number">{{ activity.inscriptionsCount }}</td>
              <td class="number">{{ activity.tryingCount }}</td>
              <td class="number">{{ activity.waitingCount }}</td>
              <td class="number">{{ activity.minimumParticipants | defaultValue('') }}</td>
              <td class="number">{{ activity.maximumParticipants | defaultValue('') }}</td>
            </tr>
          </table>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="">
          <b-form-group
              label="Année"
              label-for="school-year"
              description=""
          >
            <b-select
              id="school-year"
              v-model="selectedSchoolYear"
              :disabled="schoolYears.length === 1"
              @change="onSchoolYearChanged()"
            >
              <b-select-option
                v-for="schoolYear of schoolYears"
                :key="schoolYear.id"
                :value="schoolYear"
              >
                {{ schoolYear.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </div>
        <div class="recent-items text-right">
          <h3>Accès récent</h3>
          <p v-if="recentActivities.length === 0">-</p>
            <div v-for="activity in recentActivities" v-bind:key="activity.id" class="recent-line">
              <router-link :to="getLinkToActivity(activity)">
                {{ activity.name }}
                <div class="small2">{{ activity.schoolYear.name }}</div>
              </router-link>
            </div>
        </div>
      </b-col>
    </b-row>
    <multi-activities-select-modal
      :activities="displayedActivities"
      title="Feuilles de présence"
      @selected="onSelectMultiActivitiesForPrint"
    ></multi-activities-select-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import PagesList from '@/components/Pages/PagesList'
import SearchActivity from '@/components/Activities/SearchActivity'
import ActivityInscriptionsCountBadge from '@/components/Activities/ActivityInscriptionsCountBadge'
import DisableActivityButton from '@/components/Activities/DisableActivityButton'
import MultiActivitiesSelectModal from '@/components/Activities/MultiActivitiesSelectModal'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import { ActivitiesMixin } from '@/mixins/activities'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeActivity } from '@/types/activities'
import { makeChoice } from '@/types/base'
import { ContactSource } from '@/types/contacts'
import { makeSchoolYear } from '@/types/schools'
import { distinct } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'
import { compareStrings } from '@/utils/sorting'
import { slugify } from '@/utils/strings'

export default {
  name: 'activities',
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    MultiActivitiesSelectModal,
    CounterLabel,
    DisableActivityButton,
    LoadingGif,
    PageHeader,
    PagesList,
    SearchActivity,
    ActivityInscriptionsCountBadge,
  },
  props: {
    youthHome: [String, Number],
  },
  data() {
    return {
      activities: [],
      activitySearch: '',
      searchedActivities: [],
      showAllActivities: false,
      recentActivities: [],
      activitiesCount: -1,
      activitiesLoading: 'activities',
      searchLoading: 'searchActivities',
      activitiesCountLoading: 'activitiesCount',
      schoolYears: [],
      selectedSchoolYear: null,
      visibleHeirs: new Map(),
      selectedDisplayOption: null,
      loaded: false,
      displayOptions: [
        makeChoice({ id: 1, name: 'Actives', }),
        makeChoice({ id: 2, name: 'Suspendues', }),
        makeChoice({ id: 3, name: 'Toutes', })
      ],
    }
  },
  watch: {
    youthHome: function() {
      this.activities = []
      this.showAllActivities = false
      this.onLoaded()
    },
    selectedSchoolYear: function() {
      this.activities = []
      this.loadActivitiesCount()
    },
    category: function() {
      this.activities = []
      this.showAllActivities = false
      this.onLoaded()
    },
  },
  computed: {
    activitiesTitle() {
      const menu = this.getActivityMenu()
      if (menu) {
        return menu.label
      } else {
        return 'Activités'
      }
    },
    isYouthHome() {
      return +this.youthHome
    },
    filteredActivities() {
      let activities = []
      let mainActivities = []
      if (this.activitySearch) {
        mainActivities = this.searchedActivities
      } else if (this.showAllActivities) {
        mainActivities = this.activities
      }
      for (const activity of mainActivities) {
        activities.push(activity)
        if (this.areHeirsVisible(activity)) {
          activities = activities.concat(activity.heirs)
        }
      }
      return activities
    },
    displayedActivities() {
      let activities = this.filteredActivities
      if (this.selectedDisplayOption) {
        if (this.selectedDisplayOption.id === 1) {
          activities = activities.filter(elt => !elt.disabled)
        } else if (this.selectedDisplayOption.id === 2) {
          activities = activities.filter(elt => elt.disabled)
        }
      }
      return distinct(activities).sort(
        (act1, act2) => {
          const num1 = act1.disabled ? 1 : -1
          const num2 = act2.disabled ? 1 : -1
          if (num1 === num2) {
            return compareStrings(act1.name.toLowerCase(), act2.name.toLowerCase())
          } else {
            return num1
          }
        }
      )
    },
    showMenu() {
      return (!this.isLoading(this.activitiesLoading) && !this.activitySearch && !this.showAllActivities)
    },
    canView() {
      if (this.isYouthHome) {
        return this.hasPerm('youth.view_home')
      } else {
        return this.hasPerm('activities.view_coreactivity')
      }
    },
    contactsSource() {
      return '' + (this.isYouthHome ? ContactSource.YouthHomes : ContactSource.Activities)
    },
    activitiesSuffix() {
      let text = ''
      const menu = this.getActivityMenu()
      if (menu && menu.category) {
        text = ' ' + menu.label
      }
      return text
    },
  },
  mounted() {
    this.selectedDisplayOption = this.displayOptions[0]
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    updateRoute() {
      if (this.loaded) {
        const query = {
          category: this.category,
        }
        if (this.selectedSchoolYear) {
          query.year = this.selectedSchoolYear.id
        }
        if (this.activitySearch) {
          query.search = this.activitySearch
        }
        if (this.showAllActivities) {
          query.all = '1'
        }
        router.push({ path: this.$route.path, query: query, })
      }
    },
    getActivityCounter(choice) {
      let activities = this.filteredActivities
      if (choice.id === 1) {
        activities = activities.filter(elt => !elt.disabled)
      } else if (choice.id === 2) {
        activities = activities.filter(elt => elt.disabled)
      }
      return activities.length
    },
    getLinks() {
      if (!this.youthHome && this.displayedActivities.length) {
        return [
          {
            id: 2,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        ]
      } else {
        return []
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      let slug = 'activites'
      if (this.activitySearch) {
        slug = slugify(this.activitySearch)
      }
      const docSlug = slug + '-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onLoaded() {
      this.activitySearch = this.$route.query.search || ''
      this.showAllActivities = !!this.$route.query.all
      this.loaded = true
      await this.loadSchoolYears()
      if (this.$route.query.year) {
        const year = +this.$route.query.year
        this.selectedSchoolYear = this.schoolYears.find(elt => elt.id === year) || null
      } else {
        if (this.schoolYears.length >= 1) {
          this.selectedSchoolYear = this.schoolYears[0]
        } else {
          this.selectedSchoolYear = null
        }
      }
      await this.loadActivitiesCount()
      await this.loadRecentActivities()
      // refresh
      if (this.showAllActivities) {
        await this.loadActivities()
      }
    },
    onBack() {
      this.showAllActivities = false
      this.activitySearch = ''
      this.updateRoute()
    },
    onShowMenu(evt) {
      this.showAllActivities = false
      this.updateRoute()
    },
    async onShowAllActivities(evt) {
      this.activitySearch = ''
      await this.loadActivities()
      this.showAllActivities = true
      this.updateRoute()
    },
    getLinkToActivity(activity) {
      if (this.isYouthHome) {
        return { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
    },
    getLinkToActivityPayments(activity) {
      return { name: 'activities-payments', params: { activityId: '' + activity.id, youthHome: '' + this.isYouthHome, }, }
    },
    getLinkToActivitySeances(activity) {
      return { name: 'activities-seances', params: { activityId: '' + activity.id, youthHome: '' + this.isYouthHome, }, }
    },
    selectMultiActivitiesForPrint() {
      this.$bvModal.show('bv-multi-activities-select-modal')
    },
    onSelectMultiActivitiesForPrint(event) {
      const activityIds = '' + event.activities.map(elt => elt.id).join('-')
      router.push(
        { name: 'activities-seances', params: { activityId: activityIds, youthHome: '0', }, }
      )
    },
    async loadActivitiesCount() {
      this.startLoading(this.activitiesCountLoading)
      let url = '/api/activities/activities/count/?category=' + this.category
      if (this.isYouthHome) {
        url = '/api/youth/activities/count/?'
      }
      if (this.selectedSchoolYear && this.selectedSchoolYear.id) {
        url += '&school_year=' + this.selectedSchoolYear.id
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.activitiesCount = resp.data.count || 0
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.activitiesCountLoading)
    },
    async loadSchoolYears() {
      let url = '/api/active-school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(
          elt => makeSchoolYear(elt)
        )
        if (this.schoolYears.length > 1) {
          this.schoolYears = [makeSchoolYear({ name: 'Toutes', })].concat(this.schoolYears)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadActivities() {
      this.startLoading(this.activitiesLoading)
      let url = '/api/activities/activities/?category=' + this.category
      if (this.isYouthHome) {
        url = '/api/youth/activities/?'
      }
      if (this.selectedSchoolYear && this.selectedSchoolYear.id) {
        url += '&school_year=' + this.selectedSchoolYear.id
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.visibleHeirs = new Map()
        this.activities = resp.data.map(elt => makeActivity(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.activitiesLoading)
    },
    async loadRecentActivities() {
      let url = '/api/activities/activities/recently_accessed/?category=' + this.category
      if (this.isYouthHome) {
        url = '/api/youth/activities/recently_accessed/'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.recentActivities = resp.data.map(elt => makeActivity(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    toggleHeirs(activity) {
      let value = this.visibleHeirs.get(activity.id)
      this.visibleHeirs.set(activity.id, !value)
      this.visibleHeirs = new Map(this.visibleHeirs)
    },
    areHeirsVisible(activity) {
      return !!this.visibleHeirs.get(activity.id)
    },
    isHeirHighlighted(activity) {
      if (activity.inheritFrom) {
        return !!this.visibleHeirs.get(activity.inheritFrom.id)
      }
      return false
    },
    activitiesLabel(value) {
      if (value >= 0) {
        let text = '' + value + ' '
        if (this.isYouthHome) {
          text += (value > 1 ? 'accueil de loisirs' : 'accueils de loisirs')
        } else {
          text += (value > 1 ? 'activités' : 'activité')
          const menu = this.getActivityMenu()
          if (menu && menu.category) {
            text += ' ' + menu.label
          }
        }
        return text
      }
      return ''
    },
    activityStyle(activity) {
      return {
        backgroundColor: activity.schoolYear.backgroundColor,
        color: activity.schoolYear.textColor,
        maxHeight: '20px',
        border: 'solid 1px #888',
      }
    },
    onSchoolYearChanged() {
      this.showAllActivities = false
      this.updateRoute()
    },
    onSearch(event) {
      if (this.loaded) {
        this.activitySearch = event.text
        this.searchedActivities = event.activities
        this.updateRoute()
      }
    },
    onActivityUpdated(activity) {
      const activities = []
      for (const elt of this.activities) {
        if (elt.id === activity.id) {
          activities.push(activity)
        } else {
          for (let heirIndex = 0; heirIndex < elt.heirs.length; heirIndex++) {
            if (elt.heirs[heirIndex].id === activity.id) {
              elt.heirs[heirIndex] = activity
            }
          }
          activities.push(elt)
        }
      }
      if (this.searchedActivities.length) {
        this.searchedActivities = activities
      } else {
        this.activities = activities
      }
    },
  },
}
</script>

<style lang="less">
.small-text-counter {
  font-size: 11px;
  font-style: italic;
}
.buttons-cols {
  text-align: right;
  .btn {
    margin-left: 2px;
  }
}
.activity-line.heir {
  background: #e8eca8;
}
.activity-line:nth-of-type(odd).heir {
}
.activity-line.disabled {
  background: #ffc0c0;
}
.activity-line:nth-of-type(odd).disabled {
  background: #eab1b1;
}
.activity-line.disabled .activity-name {
  font-style: italic;
}
.keyword {
  display: inline-block;
  padding: 1px 3px;
  font-size: 10px;
  border: solid 1px #ccc;
  margin-right: 2px;
  margin-bottom: 1px;
}
.select-small {
  font-size: 13px;
}
</style>
