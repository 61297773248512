<template>
  <span class="multi-activities-select">
    <b-modal
      dialog-class="modal-lg"
      id="bv-multi-activities-select-modal"
      @ok.prevent="onOK()"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Voir"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-chess-knight"></i> {{ title }}</b>
      </template>
      <b-row>
        <b-col>
          <check-box-select
            id="activities"
            :choices="activities"
            :initial-value="activities"
            @changed="activitiesChanged($event)"
          ></check-box-select>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import router from '@/router'

export default {
  name: 'MultiActivitiesSelectModal',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    activities: Array,
    title: {
      type: String,
      default: 'Sélectionner les activités',
    },
  },
  data() {
    return {
      selectedActivities: [],
    }
  },
  mounted() {
    this.selectedActivities = this.activities
  },
  computed: {
    formInvalid() {
      return (this.selectedActivities.length === 0)
    },
  },
  watch: {
    activities: function() {
      this.selectedActivities = this.activities
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    activitiesChanged(event) {
      this.selectedActivities = event.choices
    },
    async onOK() {
      this.$emit('selected', { activities: this.selectedActivities, })
    },
  },
}
</script>

<style scoped>
</style>
